import React from 'react';

import { PageHeader } from '../../common';

export default function Subscriptions() {

   return (
      <div>
          <PageHeader title='Subscriptions' />
      </div>
   )
}
